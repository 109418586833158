<template>
  <v-container>
    <v-row>
      <v-col cols="12"
        md="11">
        <h1>Jobs Management</h1>
      </v-col>

      <v-col cols="12"
        md="1">
        <v-menu bottom
        left>
          <template #activator="{ on, attrs }">
            <v-btn icon
              v-bind="attrs"
              v-on="on">
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-list rounded>
            <v-subheader>Menu</v-subheader>
            <v-list-item-group>
              <v-list-item dense
                :to="{ name: 'singleJob' }">
                <v-list-item-icon>
                  <v-icon>mdi-message-text-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>Create Single Job</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item dense
                :to="{ name: 'loadJobs' }">
                <v-list-item-icon>
                  <v-icon>mdi-message-bulleted</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>Create Jobs from file</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item dense
                :to="{ name: 'singleJobByMISIDN' }">
                <v-list-item-icon>
                  <v-icon>mdi-message-text-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>Create Single Jobs by MSISDN</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item dense
                :to="{ name: 'loadJobsByMISIDN' }">
                <v-list-item-icon>
                  <v-icon>mdi-message-bulleted</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>Create Jobs from file by MSISDN</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-divider />
              <v-list-item dense
                :to="{ name: 'jobByBoardcast' }">
                <v-list-item-icon>
                  <v-icon>mdi-message-processing-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>Create Jobs By Boardcast</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item dense
                :to="{ name: 'jobByCustomCodition' }">
                <v-list-item-icon>
                  <v-icon>mdi-message-processing-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>Create Jobs By Custom Conditions</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-menu>
      </v-col>
      <job-calendar :jobList="convertDate" />
    </v-row>
    <v-dialog v-model="openFormSingle"
      max-width="950px">
      <single-job />
    </v-dialog>
    <v-dialog v-model="openFormLoadJobs"
      max-width="950px">
      <load-jobs />
    </v-dialog>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import * as moment from 'moment'

export default {
  components: {
    JobCalendar: () => import('@/components/jobs/newCalendar.vue'),
    SingleJob: () => import('@/views/Jobs/jobSingleForm.vue'),
    LoadJobs: () => import('@/views/Jobs/loadJobForm.vue')
  },

  data: () => ({
    submenu: [
      {
        text: 'Create Single Job',
        icon: 'mdi-message-text-outline',
        link: 'openFormSingle = true'
      },
      { text: 'Create Load List', icon: 'mdi-message-text-outline' }
    ],
    openFormSingle: false,
    openFormLoadJobs: false,
    convertDate: [],
    timeJob: {
      start_time: 0,
      end_time: 0
    },
  }),

  async created() {
    const dateCurrent = new Date();
    // const add30days = dateCurrent.setDate(dateCurrent.getDate() + 30);

    // this.timeJob.start_time = dateCurrent.setDate(dateCurrent.getDate() - 30);
    this.timeJob.start_time = moment(dateCurrent).subtract(3, 'months').endOf('month').valueOf();
    this.timeJob.end_time = moment(dateCurrent).add(1, 'months').endOf('month').valueOf();


    await this.$store.dispatch('jobManagement/GET_ALL_JOBS', this.timeJob);
  },

  computed: {
    ...mapGetters({
      jobsList: 'jobManagement/GET_JOBS'
    })
  },

  watch: {
    jobsList (val) {
      (val.jobs || []).map(item => {
        return {
          ...item,
          name: item.name
        }
      }).map(d => {
          let colorStatus = '';

          switch (d.status) {
            case 'failed':
              colorStatus = '#D84315'
              break;
          
            case 'canceled':
              colorStatus = '#FFEA00'
              break;

            case 'scheduled':
              colorStatus = '#00ACC1'
              break;
            
            case 'in-progress':
              colorStatus = '#6A1B9A'
              break;
            
            case 'skipped':
              colorStatus = '#6D4C41'
              break;
            
            case 'completed':
              colorStatus = '#7CB342'
              break;

            default:
              colorStatus = '#E8EAF6'
              break;
          }

          return this.convertDate.push({              
            id: d._id,
            title: d.name,
            start: d.tasks[0].startTime === undefined ? d.startTime : d.tasks[0].startTime,
            end: d.tasks[0].endTime === undefined ? d.endTime : d.tasks[d.tasks.length - 1].endTime,
            totalEstimateIds: d.totalEstimateIds,
            totalIds: d.totalIds,
            titleEn: d.titleEn,
            messageEn: d.messageEn,
            titleTh: d.titleTh,
            messageTh: d.messageTh,
            deepLink: d.deepLink,
            imageId: d.imageId,
            status: d.status,
            detail: d.tasks,
            color: colorStatus
          });
        });
    }
  },

  methods: {
    closeSingleForm(val) {
      this.openFormSingle = val;
    },
  }
};
</script>
